import logo from "./function_logo_white.png";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TestButtons from "./TestButtons";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <p>Soon More!</p>
            </header>
          </div>
        </Route>
        <Route path="/testbuttons">
          <TestButtons />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
