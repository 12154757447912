const TestButtons = () => {
  return (
    <div>
      <h1>Test Buttons</h1>
      <button>Test 1</button>
      <button>Test 2</button>
    </div>
  );
};

export default TestButtons;
